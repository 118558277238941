body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #f5f5f5;
}

input, select, button {
  margin: 10px;
  padding: 10px;
  width: 200px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

input:focus, select:focus, button:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

button {
  background-color: #4a90e2;
  color: #ffffff;
  cursor: pointer;
  border-color: #007BFF;
}

button:hover {
  background-color: #0056b3;
}

#map {
  height: 400px;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#results-table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
}

#results-table > table {
  max-width: 600px;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
}

#results-table th, #results-table td {
  padding: 12px 18px;
  border-bottom: 1px solid #e5e5e5;
}

#results-table th {
  background-color: #4a90e2; /* Adding a pleasing blue color */
  color: white;
  font-weight: bold;
  letter-spacing: 0.5px;
}

#results-table tr:last-child td {
  border-bottom: none;
}

#results-table tr:hover {
  background-color: #f3f3f3;  /* A subtle hover effect */
}

#results-table h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

#results-table p {
  font-size: 18px;
  margin-bottom: 10px;
  color: #666;
}

#primary-result {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #4a90e2;
}

#primary-result:hover {
  background-color: #f3f3f3;

}

#primary-result > div {
  max-width: 500px;
  width: 100%;
  text-align: center;
}

#primary-result h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

#primary-result p {
  font-size: 18px;
  margin-bottom: 10px;
  color: #666;
}